﻿@use "../ezentrum_variables";

[data-ez-module-lightbox] img:hover {
  cursor: pointer; }
.slider-container {
  position: relative;
  overflow: hidden;
  font-size: 30px; }
  .slider-container .item {
    position: absolute;
    top: 0px; }
    .slider-container .item [data-ez-item="video"] iframe {
      max-width: 100%; }
  .slider-container .slider-thumbs {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    left: 25%;
    right: 25%;
    bottom: 0px;
    top: 80%;
    justify-content: space-between; }
    .slider-container .slider-thumbs .icon {
      padding: 5px;
      background-color: black;
      color: white;
      transition: transform 0.2s ease-in-out; }
    .slider-container .slider-thumbs .icon:hover {
      cursor: pointer;
      transform: scale(1.1); }
    .slider-container .slider-thumbs .icon {
      width: 25px;
      height: 25px; }
    .slider-container .slider-thumbs .thumb {
      position: relative; }
      .slider-container .slider-thumbs .thumb .title {
        position: absolute;
        left: calc( -150px / 2 + ( 25px / 2 ));
        bottom: calc( 100% + 15px);
        width: 150px;
        height: 50px;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        align-items: center;
        background-color: black;
        opacity: 0.8;
        justify-content: center;
        color: white;
        text-align: center;
        font-weight: bold; }
      .slider-container .slider-thumbs .thumb .title:after {
        content: "";
        position: absolute;
        top: 100%;
        left: calc( ( 150px / 2 ) - 10px);
        border-style: solid;
        border-width: 10px 10px 0px 10px;
        border-color: black transparent transparent transparent;
        opacity: 0.8; }
    .slider-container .slider-thumbs .thumb:not(:hover) .title {
      display: none; }
    .slider-container .slider-thumbs .thumb.active {
      background-color: lightskyblue; }
  .slider-container .slider-buttons .previous,
  .slider-container .slider-buttons .next {
    position: absolute;
    width: calc( 25% - 5px);
    height: 100%;
    z-index: 1; }
    .slider-container .slider-buttons .previous .icon,
    .slider-container .slider-buttons .next .icon {
      padding: 5px;
      background-color: black;
      color: white;
      transition: transform 0.2s ease-in-out; }
    .slider-container .slider-buttons .previous .icon:hover,
    .slider-container .slider-buttons .next .icon:hover {
      cursor: pointer;
      transform: scale(1.1); }
  .slider-container .slider-buttons .previous:hover,
  .slider-container .slider-buttons .next:hover {
    cursor: pointer; }
  .slider-container .slider-buttons .previous {
    left: 5px;
    text-align: left; }
  .slider-container .slider-buttons .next {
    right: 5px;
    text-align: right; }

﻿/* _ezentrum_default_productlist.scss */

@use "ezentrum_fonts";
@use "ezentrum_variables";
@use "../scss_libraries/ezentrum_mixins";

#ez_wrapper {
	#ez_content_fullwidth {
		#ez_content_wrapper {
			#ez_content {
				.ez_productlist_headline {
					margin: 0;
					padding:0;
					width:100%;
				}
				.ez_productlist_description {
					margin: 0;
					padding:0;
					width:100%;
				}
				.ez_productlist_sort {
					margin: 0;
					padding:0;
					width:100%;
				}
				.ez_productlist {
					@include ezentrum_mixins.display-flex();
					@include ezentrum_mixins.flex-justify-content(space-between);
					flex-flow:row wrap;
					margin:0 -1 * ezentrum_variables.$global-margin;
					padding:0;
					.ez_product_wrapper {
						position:relative;
						margin:ezentrum_variables.$global-margin;
						padding:0;
						width:ezentrum_variables.$productlist-elementwidth;
						flex:1 0 auto;
						border:1px solid ezentrum_variables.$secondary-color;
						border-radius:ezentrum_variables.$global-radius;
						.ez_product {
							margin:0;
							padding:ezentrum_variables.$global-padding;
							.ez_productimage {
								padding:ezentrum_variables.$global-padding;
								width:100%;
								height:ezentrum_variables.$productlist-imageheight;
								text-align:center;
								img {
									max-width:100%;
									max-height:100%;
								}
							}
							.ez_productname {
								height:2 * ezentrum_variables.$body-line-height;
								@include ezentrum_mixins.break-word();
								overflow:hidden;
								h2 {
									font-size:ezentrum_variables.$body-font-size;
								}
							}
							.ez_productprice {
								text-align:right;
								.ez_normalpreis {}
								.ez_grundpreis {}
								.ez_sonderpreis {}
								.ez_basispreis {}
								.ez_currency {}
								.ez_addition {}
							}
							.ez_availability {
								position:absolute;
								top:8px;
								right:8px;
								text-align:right;
								@include ezentrum_mixins.small-text;
								.avail_good {}
								.avail_medium {}
								.avail_bad {}
							}
							.ez_productstatus {
								position:absolute;
								display:block;
								top:0;
								left:0;
								width:ezentrum_variables.$productlist-statusicon-width;
								height:ezentrum_variables.$productlist-statusicon-height;
								.ez_aktion, .ez_neu, .ez_eol {
									display:block;
									width:100%;
									height:100%;
									background-size:contain;
									b {display:none;}
								}
								.ez_aktion {background-image:url(/resources/images/icons/icon_discount.svg);}
								.ez_neu {background-image:url(/resources/images/icons/icon_new.svg);}
								.ez_eol {
									margin:4px;
									width:calc(100% - 8px);
									height:calc(100% - 8px);
									background-image:url(/resources/images/icons/icon_eol.svg);
								}
							}
							.ez_productstatus_sortiment {
								position:absolute;
								display:block;
								top:2px;
								right:2px;
								width:0.8 * ezentrum_variables.$productlist-statusicon-width;
								height:0.8 * ezentrum_variables.$productlist-statusicon-height;
								.ez_meinsortiment {
									display:block;
									width:100%;
									height:100%;
									background-image:url(/resources/images/icons/icon_assortment.svg);
									background-size:contain;
									b {display:none;}
								}
							}
						}
						&.dummy {
							border:none;
							background:transparent;
						}
					}
				}
			}
		}
	}
}


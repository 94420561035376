﻿/* _ezentrum_customize.scss for individual styling elements */
/* ########## start: import ezentrum_variables ########## */

@use "ezentrum_variables";
@use "../scss_libraries/ezentrum_mixins";

/* ########## stop: import ezentrum_variables ########## */

body {}
h1, h2, h3, h4, h5, h6 {
	font-weight:400;
}
button, .button {
	&.secondary {
		background-color:ezentrum_variables.$secondary-color;
	}
}

.small-text {
	font-size:calc(0.7 * #{ezentrum_variables.$body-font-size});
}

.ez_price_info {
	font-size:0.8em;
	color:#c00;
}

#ez_wrapper {
	#ez_header_fullwidth {
		#ez_header1_fullwidth {
			position:relative;
			overflow:visible;
			z-index:unset;
			#ez_header1_wrapper {
				position:relative;
				margin-left:60px;
				padding-left:80px;
				@include ezentrum_mixins.for-size(tablet-landscape-up) {
					margin-left:auto;
					padding-left:150px;
				}
				div.logo {
					position:absolute;
					top:ezentrum_variables.$global-padding;
					left:ezentrum_variables.$global-padding;
					z-index:10;
					img.logo {
						max-height:50px;
						@include ezentrum_mixins.for-size(tablet-landscape-up) {
							max-height:7rem;
						}
					}
				}
				.ez_header1_section {
					&.ez_headernavi_left {
						display:none;
						@include ezentrum_mixins.for-size(tablet-portrait-up) {
							@include ezentrum_mixins.display-flex;
						}
					}
					.ez_header1_item {
						overflow:visible;
						.ez_headernavi {
							margin-top:1rem;
							width:100%;
							i {
								margin-right:ezentrum_variables.$global-margin;
							}
							ul {
								margin:0;
								padding:0;
								list-style-type:none;
								width:100%;
								li {
									float:left;
									margin-right:20px;
									&.ez_languageselect {
										#language-menu > span {
											display:none;
											@include ezentrum_mixins.for-size(tablet-portrait-up) {
												display:inline;
											}
										}
										.dropdown-menu {
											.dropdown-item {
												.flag {
													height:1.6rem;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		#ez_header2_fullwidth {
			position:relative;
			top:0;
			z-index:unset;
			#ez_header2_wrapper {
				padding-left:ezentrum_variables.$global-padding;
				@include ezentrum_mixins.for-size(tablet-landscape-up) {
					padding-left:150px;
				}
				.ez_header2_section {
					width:100%;
					@include ezentrum_mixins.for-size(tablet-portrait-up) {
						width:50%;
					}
					.ez_header2_item {
						width:100%;
						.ez_searchform {
							width:100%;
							.ez_searchcontainer {
								input[type="text"],
								.ez_searchbutton {
									background-color:rgba(255,255,255,0.3);
									color:ezentrum_variables.$body-font-color;
									i {
										color: ezentrum_variables.$body-font-color;
									}
								}
							}
						}
						nav {
							ul li {
								a, span {
									font-weight:bold;
								}
								.dropdown-menu {
									a {
										color:ezentrum_variables.$body-font-color;
										font-weight:normal;
									}
								}
								.ez_to_cart_button {
									i, span {
									color:ezentrum_variables.$white;
									}
								}
							}
							&.ez_headernavi_right {
								float:right;
								ul {
									list-style-type: none;
									li {
										float: left;
										margin-right:ezentrum_variables.$global-margin;
										&:first-child {
											padding-right:ezentrum_variables.$global-padding;
											border-right:1px solid black;
										}
										&:last-child {
											margin-right:0;
										}
										a, span {
											font-weight:normal;
											color:ezentrum_variables.$body-font-color;
										}
										i.fas {
											font-size:ezentrum_variables.$body-font-size;
											padding-top: 5px;
										}
										span {
											padding-left:ezentrum_variables.$global-padding;
										}
										ul.sub-menu {
											display:none;
										}
									}
								}
							}
						}
						.ez_headernavi_left,
						.ez_headernavi_right {
							ul.ez_level1 {
								li {
									ul.ez_level2 {
										li {
											position:relative;
											ul.ez_level3 {
												position:absolute;
												display:none;
												top:0;
												left:100%;
												li {
												}
											}
											&:hover ul.ez_level3 {
												display:block;
												opacity:1;
											}
										}
									}
								}
							}
							ul li.ez_cartpreview {
								position:relative;
								span.ez_cartpreview_positions {
									position:absolute;
									top:-18px;
									right:0;
									margin:0;
									padding:0 !important;
									width:0.8 * ezentrum_variables.$body-line-height;
									height:0.8 * ezentrum_variables.$body-line-height;
									border-radius:50%;
									@include ezentrum_mixins.small-text;
									text-align:center;
									font-weight:bold !important;
									background-color:#c60f13;
									color:ezentrum_variables.$white !important;
								}
							}
							#cart_preview {
								display: none;
								overflow: auto;
								color: #000;
								position: absolute;
								left: unset !important;
								right: 0;
								width: 400px !important;
								background-color: #fff;
								border: 1px solid black;
								top: 67px !important;
								border-top: none;
								padding-left: 20px;
								padding-right: 20px;
								outline: unset;
								.ez_cartpreview_item {
									line-height:ezentrum_variables.$body-line-height;
									.ez_cartpreview_menge {
										margin-top: 15%;
									}
								}
							}
						}
						&:last-child {
							@include ezentrum_mixins.flex-justify-content(flex-end);
						}
					}
				}
			}
		}
		#ez_header3_fullwidth {
			@include ezentrum_mixins.display-flex;
			position:relative;
			@include ezentrum_mixins.full-width;
			height:ezentrum_variables.$header3-height;
			color:ezentrum_variables.$header3-font-color;
			background-color:ezentrum_variables.$header3-background-color;
			-webkit-box-shadow: 0px 5px 11px 0px rgba(50, 50, 50, 0.08);
			box-shadow: 0px 5px 11px 0px rgba(50, 50, 50, 0.08);
			#ez_header3_wrapper {
				@include ezentrum_mixins.display-flex;
				margin-top:0;
				margin-right:auto;
				margin-bottom:0;
				margin-left:auto;
				padding:0 ezentrum_variables.$global-padding;
				width: ezentrum_variables.$global-width;
				@include ezentrum_mixins.flex-justify-content(space-between);
				.ez_header3_section {
					@include ezentrum_mixins.display-flex;
					align-items: center;
					margin:-1 * ezentrum_variables.$global-margin;
					padding:ezentrum_variables.$global-padding;
					.ez_header3_item {
						@include ezentrum_mixins.display-flex;
						margin:-1 * ezentrum_variables.$global-margin;
						padding:ezentrum_variables.$global-padding;
					}
				}
			}
		}
	}
	#ez_content_fullwidth {
		margin-top: 10px;
		#ez_content_wrapper {
			background-color:ezentrum_variables.$white;
			#ez_sidenavi_container {
				#ez_sidenavi {
					&.ez_level1 {
						> li {
							a, span {
								border-radius:ezentrum_variables.$global-radius;
								&.ez_container i {
									top:0.6rem;
								}
							}
							&.selected {
								background-color:lighten(ezentrum_variables.$gray, 30);
								border-radius:ezentrum_variables.$global-radius;
							}
							.ez_level2 {
								> li {
									> a, > span {
									}
									.ez_level3 {
										> li {
											> a, > span {
											}
										}
									}
								}
							}
						}
					}
				}
			}
			#ez_content {
				.ez_productlist {
					.ez_product_wrapper {
						background-color:rgb(237,237,237);
						border:none;
						.ez_product {
							.ez_availability {
								position:relative;
								top:unset;
								right:unset;
								vertical-align: text-bottom;
							}
							.ez_productimage {
								background-color:#fff;
							}
							.ez_productname {
								margin-top:ezentrum_variables.$global-margin;
								h2 {
									font-family: "Lato", sans-serif;
									line-height:1.4;
									color:ezentrum_variables.$body-font-color;
									text-align:left;
								}
							}
							.ez_productprice {
								.price {
									.ez_normalpreis {
										font-weight:700;
									}
								}
							}
							.ez_productnumber,
							.priceinfo {
								font-size:0.8rem;
								color:ezentrum_variables.$body-font-color;
							}
							.priceinfo {
								text-align:left;
							}
						}
					}
				}
				.ez_productdetail {
					.ez_productheadline_wrapper {
						display:none;
						margin:0;
						padding:0;
						.ez_productheadline {
						}
					}
					.ez_productimage_wrapper {
						.ez_productimage {
							img {
								border-radius:ezentrum_variables.$global-radius;
							}
						}
						.ez_additionalimages {
							.ez_thumb {
								width:100px;
								img {
									max-width:100px;
									max-height:100px;
								}
							}
						}
					}
					.ez_productdescription_wrapper {
						padding-left:ezentrum_variables.$global-padding;
					}
					.ez_productdetails_wrapper {
						margin-top: 2 * ezentrum_variables.$global-margin;
						.ez_product {
							.ez_artnr {
								font-size: ezentrum_variables.$body-font-size;
							}
						}
					}
					.ez_product {
						.ez_availability {
							vertical-align: text-bottom;
						}
					}
					.ez_resources_wrapper {
						.ez_resources {
							.ez_resources_item {
								a {
									img.ez_icon.ez_lang {
										border-top:1px solid lighten(ezentrum_variables.$gray, 20);
										border-left:1px solid lighten(ezentrum_variables.$gray, 20);
										border-right:1px solid darken(ezentrum_variables.$gray, 20);
										border-bottom:1px solid darken(ezentrum_variables.$gray, 20);
									}
								}
							}
						}
					}
					.ez_accessories_wrapper {
						.ez_accessories {
							.ez_accessories_item {
								.image {
									img {
										border-radius:ezentrum_variables.$global-radius;
									}
								}
							}
						}
					}
				}
				.ez_checkout {
					img.productimage {
						max-width:140px;
						max-height:100px;
						border-radius:ezentrum_variables.$global-radius;
					}
				}
			}
		}
	}
	#ez_footer_fullwidth {
		#ez_footer1_fullwidth {
			#ez_footer1_wrapper {
				margin-top:ezentrum_variables.$global-margin;
				padding:67px ezentrum_variables.$global-padding 45px ezentrum_variables.$global-padding;
				.ez_footer1_section {
					margin: -1 * ezentrum_variables.$global-margin;
					width:100%;
					@include ezentrum_mixins.for-size(tablet-landscape-up) {
						width:30%;
					}
					.ez_footer1_item {
						margin: -1 * ezentrum_variables.$global-margin;
						padding:ezentrum_variables.$global-padding;
						.facebook {
							color:#1e73be;
							&:hover {
								color:#FC6D6D;
							}
						}
						a:hover {
							text-decoration:none;
						}
						.logo, .cert {
							width:100%;
						}
						.logo {
							img {
								max-height:120px;
							}
						}
						.cert {
							padding:5px;
							img {
								max-width:80px;
								margin-bottom:1rem;
								border-radius:ezentrum_variables.$global-radius;
							}
						}
					}
				}
			}
		}
	}
}
#ez_wrapper #ez_header_fullwidth {
	position:relative;
	#ez_mobilemenu_wrapper {
		position:absolute;
		@include ezentrum_mixins.display-flex;
		z-index:20;
		@include ezentrum_mixins.for-size(tablet-landscape-up) {
			display:none;
		}
		#ez_mobilemenu-toggle {
			span.burgericon {
				background-color: ezentrum_variables.$primary-color;
			}
		}
	}
	#ez_header1_fullwidth #ez_header1_wrapper {
		.ez_headernavi_right_wrapper {
			display:none;
			@include ezentrum_mixins.for-size(tablet-landscape-up) {
				@include ezentrum_mixins.display-flex;
			}
		}
	}
	#ez_header2_fullwidth #ez_header2_wrapper {
		.ez_searchform_wrapper {
			@include ezentrum_mixins.for-size(tablet-landscape-up) {
			}
		}
		.ez_headernavi_right_wrapper {
			display:none;
			@include ezentrum_mixins.for-size(tablet-portrait-up) {
				@include ezentrum_mixins.display-flex;
			}
			.ez_headernavi_right {
				ul {
					li.customer {
						display:none;
						@include ezentrum_mixins.for-size(tablet-landscape-up) {
							display:list-item;
						}
					}
				}
			}
		}
	}
}


﻿@use "../ezentrum_variables";
@use "../../scss_libraries/ezentrum_mixins";

.ez_searchcontainer {
	@include ezentrum_mixins.display-flex;
	flex-flow:row nowrap;
	width:100%;
	border:1px solid ezentrum_variables.$gray;
	border-radius:ezentrum_variables.$global-radius;
	#search_input {
		margin:0;
	}
	[data-ez-search-input],
	.ez_searchinput {
		margin:0;
		width:calc(100% - 40px);
		border:none;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	.ez_searchbutton {
		margin:0;
		padding:0 ezentrum_variables.$global-padding;
		width:unset;
		border:none;
		border-left:1px solid ezentrum_variables.$gray;
		border-radius:0 ezentrum_variables.$global-radius ezentrum_variables.$global-radius 0;
		background-color:ezentrum_variables.$white;
		i {
			color:ezentrum_variables.$body-font-color;
		}
		&:hover i {
			color:lighten(ezentrum_variables.$body-font-color, 20);
		}
	}
}

.lightbox-container {
	.inner {
		.lightbox-buttons {
			.previous,
			.next,
			.close {
				margin: 0px;
				padding: 0px;
				border: none;
				background-color: transparent;
				color:ezentrum_variables.$gray;
				line-height: ezentrum_variables.$body-line-height;
				i {
					padding: ezentrum_variables.$global-padding;
					font-size:2.5 * ezentrum_variables.$body-font-size;
					background-color: transparent;
					color: ezentrum_variables.$gray;
					transition: transform 0.2s ease-in-out;
				}
			}
			.close {
				position: absolute;
				top: ezentrum_variables.$global-margin;
				right: ezentrum_variables.$global-margin;
			}
		}
	}
}
#search_output {
	margin-top: 60px;
	padding: ezentrum_variables.$global-padding;
	border-radius: ezentrum_variables.$global-radius;
	.search_picture {
		max-width: 100%;
		height: auto;
		// max-width:120px;
		// max-height:120px;
	}
}
#tabs {
	.ui-state-active {
		background-color: ezentrum_variables.$primary-color;
	}
	#filters_box {
		.tab_public_element {
			margin:ezentrum_variables.$global-margin 0;
			padding:0;
			border:1px solid ezentrum_variables.$gray;
			border-radius: ezentrum_variables.$global-radius;
			.row .col {
				padding:0 ezentrum_variables.$global-padding;
			}
			.category_headline {
				margin:0 ezentrum_variables.$global-margin;
			}
		}
	}
	.filter_icon {
		margin:0 0.5 * ezentrum_variables.$global-margin;
		width:36px;
		height:36px;
	}
	.head {
		padding-top:0.5 * ezentrum_variables.$global-padding;
	}
	.icon_minus, .icon_plus {
		top:0.5 * ezentrum_variables.$global-margin;
		right:ezentrum_variables.$global-margin;
		width:unset;
		height:unset;
		svg {
			width:24px;
			height:24px;
		}
	}
	#currentCategories {
		margin-top:ezentrum_variables.$global-margin;
	}
	#filter_brand_input {
		margin:ezentrum_variables.$global-margin;
		width:90%;
	}
	#filter_brand_content, .filter_nodeCategory {
		label {
			padding:ezentrum_variables.$global-padding;
		}
	}
	#range_slider_price {
		margin:50px auto 70px auto;
		padding:0;
		width:90%;
		height:0px;
		.ui-slider-range {
			height:10px;
		}
	}
	.next_button_container {
		@include ezentrum_mixins.display-flex();
		justify-content: center;
		align-items: center;
	}
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
    border-bottom-right-radius: ezentrum_variables.$global-radius;
}
.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
    border-bottom-left-radius: ezentrum_variables.$global-radius;
}
.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
    border-top-right-radius: ezentrum_variables.$global-radius;
}
.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
    border-top-left-radius: ezentrum_variables.$global-radius;
}

.ui-tabs {
	padding:0;
	.ui-tabs-nav, .ui-tabs-panel {
		padding:0;
	}
}
